// common js

common();

function common(){
	loaded();
	headerFunc();
	smoothScroll();
	bookingFixed();
	spmenu();
	scrollParallax();
}

function loaded(){
	var mv = $('.mainvisual');
	if (!mv.length) return;
	_mvAnimePre();
	$(window).on('load', function(){
		mv.addClass('-loaded');
		_mvAnime();
	});

	function _mvAnimePre(){
		var text = $('.mainvisual__ttl span');
		text.each(function(){
			var $this = $(this);
			var chara = $this.text().split('');
	  	$this.html('');
			chara.forEach(function (c) {
			  $this.append('<span>'+c+'</span>');
			});
		});
	}
	function _mvAnime(){
		var target = $('.mainvisual__ttl span span');

		var n = 0;
		target.each(function(){
			$(this).delay(n*80).queue(function(){
				$(this).addClass('-show');
			});
			n++;
		})
	}
}

function headerFunc(){
	_bgfill();
	$(window).scroll(function(){
		_bgfill();
	});
	function _bgfill(){
		var header = $('.l-header');
		var scroll = $(window).scrollTop();
		var line = $('.mainvisual').height() / 2;
		if (scroll >= line) {
			header.addClass('-bgfill');
		}else{
			header.removeClass('-bgfill');
		}
	}
}


// smooth scroll
function smoothScroll(){
  $(document).on('click', 'a[href^="#"]' ,function(){
    var href = $(this).attr('href');
    var target = $(href == "#" || href == "" ? 'html' : href);
    var headspace = 0;
    if($('.l-header').length){
      headspace = $('.l-header').height();
    }
    var position = target.offset().top - headspace;
    $("html, body").animate({scrollTop:position}, 700, "swing");
    return false;
  });
}


function bookingFixed(){
  //include ie bug fix
  var scroll_def_pos = 0;
  var scroll_status;
  $(window).scroll(function(){
  	if (screen.availWidth > 750) return;
    var scroll_now_pos = $(window).scrollTop();
    if(scroll_def_pos == scroll_now_pos){
      if(scroll_status == true){
        $('.btn-fixed').addClass('-show');
        // scroll_status = true;
      }else{
        $('.btn-fixed').removeClass('-show');
        // scroll_status = false;
      }
    }else if(scroll_def_pos > scroll_now_pos){
      $('.btn-fixed').addClass('-show');
      scroll_status = true;
    }else{
      $('.btn-fixed').removeClass('-show');
      scroll_status = false;
    }
    scroll_def_pos = scroll_now_pos;
  });
}


function spmenu(){
	var btn = $('.btn-menu');
	var header = $('.l-header');
	var nav = $('.gnav__nav');

	btn.on('click', function(){
		if (nav.hasClass('-active')) {
			nav.removeClass('-active');
			btn.removeClass('-active');
			header.removeClass('-bgfill2');
			nav.slideUp(600);
		}else{
			nav.addClass('-active');
			btn.addClass('-active');
			header.addClass('-bgfill2');
			nav.slideDown(600);
		}
	});

	nav.find('a').on('click', function(){
		if (screen.availWidth > 750) return;
		nav.removeClass('-active');
		btn.removeClass('-active');
		header.removeClass('-bgfill2');
		nav.slideUp(600);
	});
}


function scrollParallax(){
	_init();
	function _init(){
	  $(window).on('load', function(){
	    _parallax();
	  });
	  $(window).scroll(function(){
	    _parallax();
	  });
	}
	function _parallax(){
	  var scroll = $(window).scrollTop();
	  var wH = $(window).height();
	  $('.parallaxItem').each(function(){
	    var dT = $(this).offset().top;
	    var dH = $(this).innerHeight();
	    var rate = $(this).attr('data-rate');
	    var dataA = $(this).attr('data-a');
	    var once = true;
	    if(rate == undefined){
	      rate = 0.5;
	    }
	    rate = new Number(rate);
	    if(dT <= scroll + wH - dH*rate && scroll <= dT + dH - dH*rate){
	      $(this).stop().addClass('-animated');
		    if (dataA) {
		    	console.log(dH*rate);
		    }
	    }
	    else if(scroll + wH < dT || dT + dH < scroll){
	      if(!once){
	        $(this).stop().removeClass('-animated');
	      }
	    }
	  });
	}
}